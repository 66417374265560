// src/Footer/Footer.js

import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import emailIcon from '../../Icons/email.png';
import facebookIcon from '../../Icons/facebook.png';
import instagramIcon from '../../Icons/instagram.png';
import twitterIcon from '../../Icons/twitter.png';

const Footer = () => {
    return (
        <div className="footer">
            <div className="newsletter">
                <h2>Sign up to our newsletter & get 20% off</h2>
                <Link to="/contact" className="signup-button">SIGN UP FOR FREE</Link>
            </div>
            <div className="footer-content">
                <div className="footer-column">
                    <p>THUNDER TIME RECORDS</p>
                    <p>Your trusted music companion</p>
                </div>
                <div className="footer-column">
                    <h3>NAVIGATION</h3>
                    <Link to="/">Home</Link>
                    <Link to="/shop">Shop</Link>
                    <Link to="/about">About</Link>
                    <Link to="/contact">Contact</Link>
                </div>
                <div className="footer-column">
                    <h3>CATEGORIES</h3>
                    <Link to="https://www.instagram.com/thunder_time_records/">Buy</Link>
                </div>
                <div className="social-media icons">
                    <a href="https://facebook.com/thundertimerecords" target="_blank" rel="noopener noreferrer">
                        <img src={facebookIcon} alt="Facebook" />
                    </a>
                    <a href="https://www.instagram.com/thunder_time_records/" target="_blank" rel="noopener noreferrer">
                        <img src={instagramIcon} alt="Instagram" />
                    </a>
                    <a href="https://twitter.com/thundertimerecords" target="_blank" rel="noopener noreferrer">
                        <img src={twitterIcon} alt="Twitter" />
                    </a>
                    <a href="mailto:thunder.time.records@gmail.com" target="_blank" rel="noopener noreferrer">
                        <img src={emailIcon} alt="Email" />
                    </a>
                </div>
            </div>
            <div className="footer-bottom">
                <p>All Rights Reserved By ©Thunder Time Records</p>
            </div>
        </div>
    );
};

export default Footer;
