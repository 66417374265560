import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { productsApi } from "../api";

const initialState = {
  products: [],
  setProducts: () => {},
  fetchProducts: () => {},
  currentPage: 1,
  setCurrentPage: () => {},
  pagesCount: 1,
  pages: 1,
  paginatedProducts: [],
  genres: [],
  artists: [],
  setProductQuantity: () => {},
  removeProduct: () => {},
  setProductRating: () => {},
};

export const ProductsContext = createContext(null);

export const ProductsContextProvider = ({ children }) => {
  const [products, setProducts] = useState(initialState.products);
  const [paginatedProducts, setPaginatedProducts] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);

  const [genres, setGenres] = useState([]);
  const [artists, setArtists] = useState([]);

  const productsPerPage = 8; // No need for useMemo, it's a constant

  const lastProductIndex = useMemo(
    () => currentPage * productsPerPage,
    [currentPage, productsPerPage]
  );
  
  const firstProductIndex = useMemo(
    () => lastProductIndex - productsPerPage,
    [lastProductIndex, productsPerPage]
  );

  const pagesCount = useMemo(
    () => Math.ceil(products.length / productsPerPage),
    [products, productsPerPage]
  );

  const pages = useMemo(
    () => Array.from({ length: pagesCount }, (_, i) => i + 1),
    [pagesCount]
  );

  // Fetch products from API
  const fetchProducts = async () => {
    try {
      const data = await productsApi.getProducts();
      setProducts(data);
    } catch (error) {
      console.error("Error fetching products:", error); // Use console.error for now
    }
  };

  // Update product quantity and availability
  const setProductQuantity = async (productId, quantity) => {
    const index = products.findIndex((item) => item.id === productId);

    // Ensure the product is found
    if (index === -1) {
      console.error("Product not found");
      return;
    }

    // Update product state immutably
    setProducts((prevProducts) => [
      ...prevProducts.slice(0, index),
      {
        ...prevProducts[index],
        quantity,
        availability: quantity === 0 ? "Out of stock" : "In stock",
      },
      ...prevProducts.slice(index + 1),
    ]);

    try {
      await productsApi.updateProductQuantity(productId, quantity);
    } catch (error) {
      console.error("Error updating product quantity:", error);
    }
  };

  // Remove a product
  const removeProduct = async (productId) => {
    try {
      await productsApi.deleteProduct(productId);
      setProducts((prevProducts) =>
        prevProducts.filter((product) => product.id !== productId)
      );
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };

  // Update product rating
  const setProductRating = (productId, rating) => {
    const index = products.findIndex((item) => item.id === parseInt(productId));

    // Ensure the product is found
    if (index === -1) {
      console.error("Product not found");
      return;
    }

    // Update product state immutably
    setProducts((prevProducts) => [
      ...prevProducts.slice(0, index),
      {
        ...prevProducts[index],
        rating: rating,
      },
      ...prevProducts.slice(index + 1),
    ]);
  };

  // Paginate products and set genres and artists based on paginated products
  useEffect(() => {
    if (!Array.isArray(products) || products.length === 0) {
      // Handle cases where products is not an array or empty
      setPaginatedProducts([]);
      setArtists([]);
      setGenres([]);
      return;
    }
  
    const paginatedData = products.slice(firstProductIndex, lastProductIndex);
    setPaginatedProducts(paginatedData);
    setArtists([...new Set(paginatedData.map((product) => product.artist))]);
    setGenres([...new Set(paginatedData.map((product) => product.genre))]);
  }, [products, firstProductIndex, lastProductIndex]);
  

  return (
    <ProductsContext.Provider
      value={{
        products,
        setProducts,
        fetchProducts,
        setCurrentPage,
        pages,
        paginatedProducts,
        genres,
        artists,
        setProductQuantity,
        removeProduct,
        setProductRating,
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};

export const useProductsContext = () => useContext(ProductsContext);
