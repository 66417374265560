import React from "react";
import MapComponent from "./Map";
import "./ExplorePage.css";

const ExplorePage = () => {
  return (
    <div className="explore-container">
      <div>
        <h2>HOW DO WE CLASSIFY THE CONDITION OF A VINYL?</h2>
        <div>
  <h3><b>New:</b> The record is in perfect condition, possibly never used or removed from the original shrink wrap.</h3>
  <h3><b>Almost New:</b> The record cover has fine scratches and signs of use, it is not bent and has no stains, and no part of it is missing. The record is in excellent working condition and does not show major scratches on its surface.</h3>
  <h3><b>Very Good:</b> The record cover shows signs of scratches and use, it may be bent or have other handling marks from previous owners. It is possible that there are dedications or notes on one of the cover's sides. The record is in good working condition, its surface may show fine scratches and signs of previous use.</h3>
  <h3><b>Good:</b> The record cover shows major signs of use, with superficial pieces missing, possible handwritten notes, scratches, and bends. The record is in satisfactory condition, its surface may have scratches and signs of previous use, and surface noise may be heard at times.</h3>
  <h3><b>Acceptable:</b> The record cover has marks, stains, scratches, bends, handwritten notes, dedications, or other major issues and requires restoration. The record is in uncertain condition, it may skip occasionally and could have surface noise.</h3>
</div>

      </div>
      <div className="map">
        <h2>Vinyl Shops in Romania</h2>
        <MapComponent />
      </div>
    </div>
  );
};

export default ExplorePage;
