import { Link } from "react-router-dom";
import { FaHeart, FaShoppingCart } from "react-icons/fa";
import { useWishlistContext, useCartContext, useLoginContext } from "./context";
import "./NavigationBar.css";
import { Button } from "./shared-components";
import ThunderTimeRecordsLogo from "./assets/header_logo.svg";

const NavigationBar = () => {
  const { userInfo, handleLogout } = useLoginContext();
  const { cartContent, removeAllCartItems } = useCartContext();
  const { wishlistItems } = useWishlistContext();

  const { isLoggedIn } = userInfo;
  const cartItemsCount =
    cartContent?.cartItems
      ?.map((item) => item.quantity)
      .reduce((a, b) => a + b, 0) || 0;
  const wishlistCount = wishlistItems.length;

  const logout = () => {
    removeAllCartItems();
    handleLogout();
  };

  const links = {
    left: [
      {
        path: "/shop",
        element: "Shop",
        displayIfLogged: false,
      },
      {
        path: "/explore",
        element: "Explore",
        displayIfLogged: false,
      },
      {
        path: "/community",
        element: "Community",
        displayIfLogged: false,
      },
    ],
    right: [
      {
        path: "/contact",
        element: "Contact",
        displayIfLogged: false,
      },
      {
        path: "/about",
        element: "About",
        displayIfLogged: false,
      },
      {
        path: "/wishlist",
        element: (
          <div className="wishlist-icon-wrapper">
            <FaHeart />
            {wishlistCount > 0 && (
              <span className="wishlist-count">{wishlistCount}</span>
            )}
          </div>
        ),
        displayIfLogged: true,
      },
    ],
  };

  const allLinks = links.right.filter((link) => link.displayIfLogged === false);

  const protectedLinks = links.right.filter(
    (link) => link.displayIfLogged === true
  );

  return (
    <div className="navbar-container">
      <nav className="navigation-bar">
        <div className="navigation-align-left">
          {links.left.map((link, index) => (
            <Link className="navigation-link" to={link.path} key={index}>
              <p>{link.element}</p>
            </Link>
          ))}
        </div>

        <Link to="/home" className="navigation-link header-title">
          <img
            src={ThunderTimeRecordsLogo}
            alt="THUNDER TIME RECORDS"
            style={{ height: "70px" }}
          />
        </Link>

        <div className="navigation-align-right">
          

          {allLinks.map((link, index) => (
            <Link className="navigation-link" to={link.path} key={index}>
              <p>{link.element}</p>
            </Link>
          ))}

          {isLoggedIn &&
            protectedLinks.map((link, index) => (
              <Link className="navigation-link" to={link.path} key={index}>
                <p>{link.element}</p>
              </Link>
            ))}

          
        </div>
      </nav>
    </div>
  );
};

export default NavigationBar;
