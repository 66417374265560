import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import NavigationBar from "./NavigationBar";
import { Protected } from "./shared-components";
import {
  AboutPage,
  BuyerDashboard,
  Cart,
  CommunityPage,
  ContactPage,
  ExplorePage,
  HomePage,
  OrderManagement,
  ProductDetailPage,
  ProductManagement,
  InventoryPage,
  SellerDashboard,
  SellerRegistrationForm,
  SellerReports,
  ShopPage,
  SignInSignUpPage,
  WishlistPage,
  CustomersPage,
  OrderPlaced,
  ErrorPage,
} from "./containers";
import { useCartContext, useLoginContext, useProductsContext } from "./context";
import { ErrorBoundary } from "react-error-boundary";
import { ToastContainer } from "react-toastify";

const generalRoutes = [
  {
    path: "/home",
    element: <HomePage />,
  },
  {
    path: "/community",
    element: <CommunityPage />,
  },
  {
    path: "/contact",
    element: <ContactPage />,
  },
  {
    path: "/about",
    element: <AboutPage />,
  },
  {
    path: "/explore",
    element: <ExplorePage />,
  },
  {
    path: "/shop",
    element: <ShopPage />,
  },
  {
    path: "/sign-in",
    element: <SignInSignUpPage />,
  },
  {
    path: "/seller-register",
    element: <SellerRegistrationForm />,
  },
  {
    path: "/product/:productId",
    element: <ProductDetailPage />,
  },
  {
    path: "/wishlist",
    element: <WishlistPage />,
  },
];

const protectedRoutes = [
  {
    path: "/cart",
    element: <Cart />,
    roles: ["BUYER", "ADMIN", "SELLER"],
  },
  {
    path: "/seller-dashboard",
    element: <SellerDashboard />,
    roles: ["SELLER", "ADMIN"],
  },
  {
    path: "/seller/reports",
    element: <SellerReports />,
    roles: ["SELLER", "ADMIN"],
  },
  {
    path: "/seller/products",
    element: <ProductManagement />,
    roles: ["SELLER", "ADMIN"],
  },
  {
    path: "/seller/orders",
    element: <OrderManagement />,
    roles: ["SELLER", "ADMIN"],
  },
  {
    path: "/buyer/orders",
    element: <OrderManagement />,
    roles: ["BUYER", "ADMIN"],
  },
  {
    path: "/seller/inventory",
    element: <InventoryPage />,
    roles: ["SELLER", "ADMIN"],
  },
  {
    path: "/buyer-dashboard",
    element: <BuyerDashboard />,
    roles: ["BUYER", "ADMIN"],
  },
  {
    path: "/seller/customers",
    element: <CustomersPage />,
    roles: ["SELLER", "ADMIN"],
  },
  {
    path: "/order-placed/:orderIds",
    element: <OrderPlaced />,
    roles: ["BUYER", "SELLER", "ADMIN"],
  },
];

function App() {
  const { fetchProducts } = useProductsContext();
  const { fetchCartItems } = useCartContext();
  const { userInfo } = useLoginContext();

  useEffect(() => {
    const initializeGlobalState = async () => {
      await Promise.all([fetchProducts(), fetchCartItems()]);
    };

    initializeGlobalState();
    // eslint-disable-next-line
  }, [userInfo]);

  return (
    <Router>
      <NavigationBar />
      <ErrorBoundary
        fallbackRender={({ error }) => <ErrorPage error={error} />}
      >
        <Routes>
          {generalRoutes.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}

          {protectedRoutes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                <Protected roles={route.roles} path={route.path}>
                  {route.element}
                </Protected>
              }
            />
          ))}

          <Route path="*" element={<Navigate to="/home" />} />
        </Routes>

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </ErrorBoundary>
    </Router>
  );
}

export default App;
