import React from "react";
import "./BestSeller.css";

const BestSeller = () => {
  const products = [
    {
      name: "Born To Die - Paradise Edition Vinyl",
      imageUrl: require("../../Pictures/Lana.jpeg"),
      link: "/shop",
    },
    {
      name: "SEE MORE OF BEST SELLER SEE MORE OF BEST SELLER SEE MORE OF BEST SELLER SEE MORE OF BEST SELLER SEE MORE OF BEST SELLER SEE MORE OF BEST SELLER SEE MORE OF BEST SELLER SEE MORE OF BEST SELLER SEE MORE OF BEST SELLER SEE MORE OF BEST SELLER SEE MORE OF BEST SELLER SEE MORE OF BEST SELLER SEE MORE OF BEST SELLER SEE MORE OF BEST SELLER SEE MORE OF BEST SELLER SEE MORE OF BEST SELLER",
      price: "",
      imageUrl: require("../../Pictures/Sabrina.jpeg"),
      link: "/shop",
      special: true,
    },
    {
      name: 'Hit Me Hard And Soft - 12" Splatter Vinyl',
      imageUrl: require("../../Pictures/Billie.jpeg"),
      link: "/shop",
    },
    {
      name: "The Rise And Fall Of A Midwest Princess - Vinyl",
      imageUrl: require("../../Pictures/Chappell.jpeg"),
      link: "/shop",
    },
  ];

  return (
    <div className="best-seller">
      <h2 className="title">MOST WANTED NOW</h2>
      <div className="product-container">
        {products.map((product, index) => (
          <div
            className={`product ${product.special ? "special" : ""}`}
            key={index}
          >
            <div className="product-image-container">
              <img src={product.imageUrl} alt={product.name} />
              <div className={`overlay ${product.special ? "special" : ""}`}>
                <a
                  href={product.link}
                  className={`${
                    product.special ? "more-button" : "buy-now-button"
                  }`}
                >
                  {product.special ? product.name : "Buy Now"}
                </a>
              </div>
            </div>
            {!product.special && (
              <>
                <h5>{product.name}</h5>
                <p>{product.price}</p>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BestSeller;
