// src/About/AboutPage.js

import React from "react";
import "./AboutPage.css";
import vinylImage from "../../Pictures/vinyl.jpg"; // Make sure you have a vinyl image in your Images folder

const AboutPage = () => {
  return (
    <div className="about-container">
      <h1>About Thunder Time Records</h1>
      <div className="about-content">
        <div className="about-text">
          <p>
          Welcome to Thunder Time Records, where the love for vinyl and the magic of music collide! 🎶🎧
          </p>
          <p>
          Thunder Time Records isn't just another online shop — it’s a home for vinyl enthusiasts, collectors, and music lovers alike. Born out of a desire to fill a gap in Romania's vinyl scene, we’ve created a place where the warm crackle of a record spinning is more than just nostalgia — it's a celebration of music's finest format!
          </p>
          <p>
          What started as a dream to blend technology and a passion for music has grown into a vibrant community where people can discover, buy, and sell vinyl treasures. Whether you're on the hunt for a rare gem, looking to share your collection, or just vibing with fellow music lovers, Thunder Time Records is here to keep that vinyl energy spinning strong. 🚀✨
          </p>
          <p>
          So, pull up a chair, grab your favorite record, and join us in keeping the vinyl spirit alive! Thunder Time Records is all about good music, great people, and timeless records.
         </p>
          <p> Thanks for being part of the Thunder Time journey — your vibe keeps the needle on the groove!</p>
        </div>
        <div className="about-image">
          <img src={vinylImage} alt="Vinyl Record" />
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
