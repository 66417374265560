import getAxiosInstance from "./axios.service";
import { Endpoints } from "./constants";

const registerUser = async (sellerData) => {
  try {
    const response = await getAxiosInstance().post(
      Endpoints.REGISTER,
      sellerData
    );
    return {
      token: response.data,
      success: true,
    };
  } catch (error) {
    return error;
  }
};

export const usersApi = {
  registerUser,
};
